/*
 ---- Structure ----
 * [integrationName]: {
 *   [internal name]: [external field identifier]
 * }
 *
 ---- Description ----
 * [internal name]: probably will be: email, firstname, lastname
 */

const integrationsFixedFields = {
  salesAutopilot: {
    email: 'email',
    firstname: 'mssys_firstname',
    lastname: 'mssys_lastname',
  },
  shopRenter: {
    email: 'email',
    firstname: 'firstname',
    lastname: 'lastname',
  },
  shopifyCustomer: {
    email: 'email',
    firstname: 'first_name',
    lastname: 'last_name',
    phone: 'phone',
  },
  webGalamb4Plus: {
    email: 'mail',
  },
  infusionSoft: {
    email: 'Email',
    firstname: 'FirstName',
    lastname: 'LastName',
  },
  keap: {
    email: 'Email',
  },
  klaviyo: {
    email: 'email',
    firstname: 'first_name',
    lastname: 'last_name',
    phone: 'phone_number',
  },
  aWeber: {
    email: 'email',
  },
  campaignMonitor: {
    email: 'EmailAddress',
  },
  mailUp: {
    email: 'email',
    firstname: 1,
    lastname: 2,
  },
  mailChimp: {
    email: 'EMAIL',
    firstname: 'FNAME',
    lastname: 'LNAME',
  },
  highLevel: {
    email: 'email',
    firstname: 'firstName',
    lastname: 'lastName',
    phone: 'phone',
  },
  hubSpot: {
    email: 'email',
    firstname: 'firstname',
    lastname: 'lastname',
  },
  hubSpotV2: {
    email: 'email',
    firstname: 'firstname',
    lastname: 'lastname',
  },
  emarsys: {
    email: '3',
  },
  webhook: {
    email: 'email',
    firstname: 'firstname',
    lastname: 'lastname',
    coupon_code: 'custom_fields[coupon_code]',
    coupon_title: 'custom_fields[coupon_title]',
  },
  smsBump: {
    email: 'email',
    firstname: 'name',
    lastname: 'name',
  },
  miniCrm: {
    email: 'Email',
    firstname: 'FirstName',
    lastname: 'LastName',
  },
  soundest: {
    email: 'email',
    firstname: 'firstName',
    lastname: 'lastName',
  },
  marketo: {
    email: 'email',
    firstname: 'firstName',
    lastname: 'lastName',
  },
  ontraport: {
    email: 'email',
    firstname: 'firstname',
    lastname: 'lastname',
  },
  activeCampaign: {
    email: 'email',
    firstname: 'first_name',
    lastname: 'last_name',
    phone: 'phone',
  },
  postscriptLegacy: {
    email: 'email',
    phone: 'phone',
  },
  postscript: {
    email: 'email',
    phone: 'phone',
  },
  mailerLite: {
    email: 'email',
    firstname: 'name',
    lastname: 'last_name',
  },
  moosend: {
    email: 'Email',
  },
  mailjet: {
    email: 'Email',
  },
  mailWizz: {
    email: 'EMAIL',
  },
  maileon: {
    email: 'email',
  },
  dotmailer: {
    email: 'EMAIL',
  },
  getResponse: {
    email: 'email',
  },
  mailigen: {
    email: 'EMAIL',
  },
  salesforce: {
    email: 'Email',
    firstname: 'FirstName',
    lastname: 'LastName',
  },
  acerCCDB: {
    email: 'email',
  },
  acerCCDBV2: {
    email: 'email',
  },
  attentiveV2: {
    phone: 'phone',
    email: 'email',
  },
  sendinblue: {
    email: 'EMAIL',
    phone: 'SMS',
  },
  recart: {
    phone: 'phoneNumber',
  },
  selzy: {
    email: 'Email',
    phone: 'Phone',
  },
  unas: {
    email: 'email',
    name: 'name',
  },
  theMarketer: {
    email: 'email',
    phone: 'phone',
  },
  listamesterV2: {
    email: 'email',
  },
  zapier: {
    email: 'email',
    firstname: 'firstname',
    lastname: 'lastname',
  },
};

module.exports = integrationsFixedFields;
