<template lang="pug">
.d-flex.flex-column.title-editing(:class="classes")
  template(v-if="!titleEdit")
    .d-flex.flex-column.title-holder
      om-heading.title(:h1="h1" :h2="h2" :h3="h3" :h4="h4" :h5="h5" :h6="h6" @click.native="edit")
        span {{ title }}
  template(v-else)
    om-textarea#editedTitle.edited-title(
      v-model="editedTitle"
      ref="titleInput"
      rows="1"
      required
      :maxLength="maxLength"
      style="width: 100%"
    )
</template>
<script>
  const ENTER_KEY = 13;
  const CANCEL_KEY = 27;
  export default {
    props: {
      title: { type: String, default: '' },
      h1: { type: Boolean, default: false },
      h2: { type: Boolean, default: false },
      h3: { type: Boolean, default: false },
      h4: { type: Boolean, default: false },
      h5: { type: Boolean, default: false },
      h6: { type: Boolean, default: false },
      maxLength: { type: Number, default: null },
      cancelOnBlur: { type: Boolean, default: false },
    },
    data() {
      return {
        titleEdit: false,
        editedTitle: this.title,
      };
    },
    computed: {
      classes() {
        return {
          'title-heading-1': this.h1,
          'title-heading-2': this.h2,
          'title-heading-3': this.h3,
          'title-heading-4': this.h4,
          'title-heading-5': this.h5,
          'title-heading-6': this.h6,
        };
      },
    },
    watch: {
      title() {
        this.titleEdit = false;
      },
    },
    beforeDestroy() {
      this.removeListeners();
    },
    methods: {
      addListeners() {
        window.addEventListener('click', this.listenToClickAndBlur);
        window.addEventListener('blur', this.listenToClickAndBlur);
        window.addEventListener('keydown', this.handleKeydown);
        window.addEventListener('keyup', this.resizeTextarea);
        window.addEventListener('input', this.handleInput);
      },
      removeListeners() {
        window.removeEventListener('click', this.listenToClickAndBlur);
        window.removeEventListener('blur', this.listenToClickAndBlur);
        window.removeEventListener('keydown', this.handleKeydown);
        window.removeEventListener('keyup', this.resizeTextarea);
        window.removeEventListener('input', this.handleInput);
      },
      handleInput(e) {
        this.editedTitle = e.target.value;
      },
      listenToClickAndBlur(e) {
        if (e.target?.id !== this.$refs.titleInput?.id) {
          if (this.cancelOnBlur) {
            this.cancel();
          } else {
            this.renameTitle();
          }
        }
      },
      handleKeydown(event) {
        const {
          keyCode,
          target: { value: newTitleName },
        } = event;
        if (typeof newTitleName !== 'undefined') {
          this.editedTitle = newTitleName;
        }
        if (keyCode === ENTER_KEY) {
          event.preventDefault();
          this.renameTitle();
        }
        if (keyCode === CANCEL_KEY) {
          this.cancel();
        }
      },
      resizeTextarea() {
        const textArea = this.$refs.titleInput.getInputElement();
        textArea.style.overflow = 'hidden';
        textArea.style.height = 'auto';
        textArea.style.height = `${textArea.scrollHeight}px`;
      },
      cancel() {
        this.titleEdit = false;
        this.editedTitle = this.title;
        this.removeListeners();
      },
      renameTitle() {
        this.titleEdit && this.$nextTick(() => {
          if (!this.$refs.titleInput.validate()) {
            this.$refs.titleInput.focus();
            return;
          }
          if (this.editedTitle !== this.title) {
            this.$emit('renameTitle', this.editedTitle.trim());
          }
          setTimeout(() => {
            this.titleEdit = false;
          }, 50);
          this.removeListeners();
        });
      },
      edit() {
        this.editedTitle = this.title;
        this.titleEdit = true;
        setTimeout(() => {
          const textArea = this.$refs.titleInput.getInputElement();
          this.resizeTextarea();
          textArea.focus();
          this.addListeners();
        }, 0);
      },
    },
  };
</script>

<style lang="sass">
  @import '../sass/variables/_colors'

  .title-heading-1
    textarea
      font-size: 2.25rem
      line-height: 1.667
      padding: 0 12px

  .title-heading-2
    textarea
      font-size: 2rem
      line-height: 1.25
      padding: 6px 12px

  .title-heading-3
    textarea
      font-size: 1.5rem
      line-height: 1.3
      padding: 6px 12px

  .title-heading-4
    textarea
      font-size: 1.25rem
      line-height: 1.4
      padding: 2px 12px

  .title-heading-5
    textarea
      font-size: 1.125rem
      line-height: 1.3
      padding: 3px 12px

  .title-heading-6
    textarea
      font-size: 1rem
      line-height: 1.5
      padding: 1px 12px

  .title-editing
    .edited-title
      margin: 0 -13px
      padding-bottom: 2px

    .title-holder
      .title
        width: calc(100% + 24px)
        max-width: calc(100% + 24px)
        white-space: pre-wrap      /* CSS3 */
        white-space: -moz-pre-wrap /* Firefox */
        white-space: -o-pre-wrap   /* Opera 7 */
        word-wrap: break-word
        overflow-wrap: anywhere
        cursor: text
        padding: 0 12px
        margin: 0 -12px
        line-height: 1.667
        border-top: 1px solid #fff
        border-bottom: 1px solid #fff

        span
          transition: background-color 0.5s ease, border-color 0.5s ease
          border: 1px solid transparent
          padding: 9px 11px
          margin: 0 -12px
          // https://caniuse.com/css-boxdecorationbreak
          box-decoration-break: clone
          -webkit-box-decoration-break: clone

        &:hover span
          background-color: $om-gray-100
          border: 1px solid $om-gray-100

    textarea
      font-weight: bold
      letter-spacing: 0.01rem
      margin-bottom: 0
      cursor: text
      resize: none
      width: calc(100% + 24px)
      max-width: calc(100% + 24px)
      border: 1px solid white

      &.form-control:focus
        border: 1px solid $om-orange-300
        box-shadow: none
</style>
