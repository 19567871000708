import store from '@/store';
import i18n from '@/i18n';
import { createDCUrl } from '@/utils/pncURLBuilder';

export const createDCCampaignFromCache = async ({
  url,
  userId,
  domain,
  featureMode = 'smart-ab-test',
  lastEditUrl = null,
  needCacheChanges = false,
  frequencyEnabled = false,
}) => {
  const addControlVariant = featureMode !== 'smart-ab-test';
  const { campaignId, variantId } = await store.dispatch('createDCCampaign', {
    url,
    addControlVariant,
    needCacheChanges,
  });

  const returnActiveBox = frequencyEnabled ? 'frequency' : 'condition';
  const returnUrl = `/${userId}/campaign/${campaignId}/settings?activeBox=${returnActiveBox}`;
  const backUrl = `/${userId}/campaign/${campaignId}`;
  const dcUrl = createDCUrl({
    domain,
    campaignId,
    variantId,
    locale: i18n.locale,
    returnUrl,
    backUrl,
    lastEditUrl,
    isSuperAdmin: store.getters.isSuperAdmin,
    featureMode,
    isNew: true,
  });

  return dcUrl;
};
